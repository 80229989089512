export enum CookieKeys {
  UserData = "userData",
  Email = "email",
  IsEmailVerified = "is_email_verified",
  Type = "type",
  AccessToken = "accessToken",
  RefreshToken = "refreshToken",
  JobSlug = "job_slug",
  EmailCollect = "email_collect",
  EmailCollectClose = "email_collect_close",
  Redirect = "redirect",
  WorkFairModal = "workfair_modal",
}
